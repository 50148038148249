import gql from "graphql-tag";
import { apolloClient } from "utils";
import DocumentTemplateSet from "../types.ts/DocumentTemplateSet";

const documentTemplateSetIdFixed = "d998f280-bd5e-4a30-b3db-3b9806e53e64";

export async function getGeneralDocumentTemplateSet(): Promise<DocumentTemplateSet> {
  const response = await apolloClient.query({
    query: DOCUMENT_TEMPLATE_SET,
    variables: { documentTemplateSetId: documentTemplateSetIdFixed },
    fetchPolicy: "no-cache"
  });
  if (
    response.errors ||
    response.data == null ||
    response.data.documenttemplatesets === 0 ||
    response.data.documenttemplatesets.length !== 1 ||
    response.data.documenttemplatesets[0] == null
  ) {
    throw response.errors;
  }
  console.log(response.data.documenttemplatesets);
  return response.data.documenttemplatesets[0];
}

const DOCUMENT_TEMPLATE_SET = gql`
  query documenttemplatesets($documentTemplateSetId: String!) {
    documenttemplatesets(where: { id: { equals: $documentTemplateSetId } }) {
      id
      documentTemplates(where: { deprecated: { is: FALSE } }) {
        id
        name
        displayText
        position
      }
    }
  }
`;
